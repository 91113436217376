/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ArticlesParser } from '../parsers/articles.parser';

/** Interfaces - Enums */
import FileExtension from '@leap-common/enums/file-extension.enum';
import PaginatedArticles from '../interfaces/paginated-articles.interface';
import PaginatedArticlesRestApi from '../rest-api-interfaces/paginated-articles.rest.interface';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import ArticleInfoRestApi from '../rest-api-interfaces/article-info.rest.interface';
import ArticleInfo from '../interfaces/article-info.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import ArticlesContextRestApi from '../rest-api-interfaces/articles-context.rest.interface';
import ArticleBibTexRestApi from '../rest-api-interfaces/bibtex.rest.interface';

@Injectable()
export class ArticlesService {
    constructor(private http: HttpClient, private articlesParser: ArticlesParser) {}

    performQuery(
        query: string,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        searchQuery: string,
        sortingOptions: SortingOptions,
    ): Observable<PaginatedArticles> {
        return this.http
            .post(`${environment.discoveryServerUrl}/articles-browser/details`, {
                query,
                ...filters,
                sortingBy: sortingOptions.field,
                sortingOrder: sortingOptions.order,
                pageSize,
                pageIndex,
                ...(searchQuery && { phrase: searchQuery }),
            })
            .pipe(
                map((paginatedResults: PaginatedArticlesRestApi) =>
                    this.articlesParser.parsePaginatedArticles(paginatedResults),
                ),
            );
    }

    downloadQueryArticles(
        query: string,
        filters: ExecutionFilters,
        searchQuery: string,
        sortingOptions?: SortingOptions,
        pageSize?: number,
        pageIndex?: number,
    ): Observable<Blob> {
        return this.http.post(
            `${environment.discoveryServerUrl}/articles-browser/details/download`,
            {
                query,
                ...filters,
                sortingBy: sortingOptions?.field,
                sortingOrder: sortingOptions?.order,
                ...(pageSize && { pageSize }),
                ...(pageIndex && { pageIndex }),
                ...(searchQuery && { phrase: searchQuery }),
            },
            {
                headers: new HttpHeaders({
                    Accept: `text/${FileExtension.xlsx}`,
                }),
                responseType: 'blob',
            },
        );
    }

    getArticlesInfo(ids: [string, string, string?][]): Observable<ArticleInfo[]> {
        return this.http
            .post(`${environment.discoveryServerUrl}/relevant-articles/info`, {
                cuiTuples: ids,
            })
            .pipe(
                map((articlesInfo: ResultsRestApi<ArticleInfoRestApi>) =>
                    this.articlesParser.parseArticlesInfo(articlesInfo.results),
                ),
            );
    }

    getArticles(
        ids: [string, string, string?],
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        sortingOptions: SortingOptions,
        areResultsHighlighted: boolean,
        searchQuery?: string,
    ): Observable<PaginatedArticles> {
        return this.http
            .post(
                `${environment.discoveryServerUrl}/relevant-articles/details${
                    areResultsHighlighted ? '' : '?ignoreSpans'
                }`,
                {
                    cuiTuple: ids,
                    ...filters,
                    sortingBy: sortingOptions.field,
                    sortingOrder: sortingOptions.order,
                    pageSize,
                    pageIndex,
                    ...(searchQuery && { phrase: searchQuery }),
                },
            )
            .pipe(
                map((paginatedResults: PaginatedArticlesRestApi) =>
                    this.articlesParser.parsePaginatedArticles(paginatedResults),
                ),
            );
    }

    downloadArticles(
        ids: [string, string, string?],
        filters: ExecutionFilters,
        searchQuery: string,
        sortingOptions?: SortingOptions,
        pageSize?: number,
        pageIndex?: number,
    ): Observable<Blob> {
        return this.http.post(
            `${environment.discoveryServerUrl}/relevant-articles/details/download`,
            {
                cuiTuple: ids,
                ...filters,
                sortingBy: sortingOptions?.field,
                sortingOrder: sortingOptions?.order,
                ...(pageSize && { pageSize }),
                ...(pageIndex && { pageIndex }),
                ...(searchQuery && { phrase: searchQuery }),
            },
            {
                headers: new HttpHeaders({
                    Accept: `text/${FileExtension.xlsx}`,
                }),
                responseType: 'blob',
            },
        );
    }

    downloadFullArticle(
        id: string,
        selectedTerm?: string,
        sourceName?: string,
        targetName?: string,
    ): Observable<Blob> {
        const discoveryTerms: Record<string, string> = this.articlesParser.serializeDiscoveryTerms(
            sourceName,
            targetName,
        );

        return this.http.post(
            `${environment.discoveryServerUrl}/articles/download`,
            {
                externalId: id,
                discoveryTerms,
                selectedTerm,
            },
            {
                headers: new HttpHeaders({
                    Accept: 'application/pdf',
                }),
                responseType: 'blob',
            },
        );
    }

    downloadSelectedArticles(
        ids: string[],
        extension: FileExtension,
        filters: ExecutionFilters,
        searchQuery: string,
        query: string,
        selectedTerm?: string,
        sourceName?: string,
        targetName?: string,
    ): Observable<Blob> {
        const discoveryTerms: Record<string, string> = this.articlesParser.serializeDiscoveryTerms(
            sourceName,
            targetName,
        );
        const context: ArticlesContextRestApi = this.articlesParser.serializeArticlesContext(
            filters,
            searchQuery,
            query,
        );
        const headers: HttpHeaders =
            extension === FileExtension.pdf
                ? undefined // the PDFs are downloaded inside a zip file
                : new HttpHeaders({
                      Accept: `text/${extension}`,
                  });

        return this.http.post(
            `${environment.discoveryServerUrl}/articles/download/batch`,
            {
                externalIds: ids,
                discoveryTerms,
                selectedTerm,
                format: extension,
                context,
            },
            {
                headers,
                responseType: 'blob',
            },
        );
    }

    getBibTex(id: string): Observable<string> {
        return this.http
            .post(`${environment.discoveryServerUrl}/articles/bibtex`, {
                externalId: id,
            })
            .pipe(map(({ bibtex }: ArticleBibTexRestApi) => bibtex));
    }

    downloadBibTex(id: string): Observable<Blob> {
        return this.http.post(
            `${environment.discoveryServerUrl}/articles/bibtex/download`,
            {
                externalId: id,
            },
            {
                headers: new HttpHeaders({
                    Accept: 'application/x-bibtex',
                }),
                responseType: 'blob',
            },
        );
    }
}
