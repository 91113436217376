/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromMetadata from './metadata.reducer';

export const getMetadataState = createSelector(getDataState, (state: DataState) => state.metadata);

export const getErrors = createSelector(getMetadataState, fromMetadata.getErrors);
export const getLabs = createSelector(getMetadataState, fromMetadata.getLabs);
export const getLabsLoading = createSelector(getMetadataState, fromMetadata.getLabsLoading);
export const getLabsLoaded = createSelector(getMetadataState, fromMetadata.getLabsLoaded);
export const getHealthLabels = createSelector(getMetadataState, fromMetadata.getHealthLabels);
export const getHealthLabelsLoading = createSelector(
    getMetadataState,
    fromMetadata.getHealthLabelsLoading,
);
export const getHealthLabelsLoaded = createSelector(
    getMetadataState,
    fromMetadata.getHealthLabelsLoaded,
);
export const getIdentifiers = createSelector(getMetadataState, fromMetadata.getIdentifiers);
export const getIdentifiersLoading = createSelector(
    getMetadataState,
    fromMetadata.getIdentifiersLoading,
);
export const getIdentifiersLoaded = createSelector(
    getMetadataState,
    fromMetadata.getIdentifiersLoaded,
);
export const getProteinOrigins = createSelector(getMetadataState, fromMetadata.getProteinOrigins);
export const getProteinOriginsLoading = createSelector(
    getMetadataState,
    fromMetadata.getProteinOriginsLoading,
);
export const getProteinOriginsLoaded = createSelector(
    getMetadataState,
    fromMetadata.getProteinOriginsLoaded,
);
export const getPatentsCounts = createSelector(getMetadataState, fromMetadata.getPatentsCounts);
export const getPatentsCountsLoading = createSelector(
    getMetadataState,
    fromMetadata.getPatentsCountsLoading,
);
export const getPatentsCountsLoaded = createSelector(
    getMetadataState,
    fromMetadata.getPatentsCountsLoaded,
);
export const getContainingIngredients = createSelector(
    getMetadataState,
    fromMetadata.getContainingIngredients,
);
export const getContainingIngredientsLoading = createSelector(
    getMetadataState,
    fromMetadata.getContainingIngredientsLoading,
);
export const getContainingIngredientsLoaded = createSelector(
    getMetadataState,
    fromMetadata.getContainingIngredientsLoaded,
);
export const getRelationshipsInfo = createSelector(
    getMetadataState,
    fromMetadata.getRelationshipsInfo,
);
export const getRelationshipsInfoLoading = createSelector(
    getMetadataState,
    fromMetadata.getRelationshipsInfoLoading,
);
export const getRelationshipsInfoLoaded = createSelector(
    getMetadataState,
    fromMetadata.getRelationshipsInfoLoaded,
);
export const getStudyTypes = createSelector(getMetadataState, fromMetadata.getStudyTypes);
export const getStudyTypesLoading = createSelector(
    getMetadataState,
    fromMetadata.getStudyTypesLoading,
);
export const getStudyTypesLoaded = createSelector(
    getMetadataState,
    fromMetadata.getStudyTypesLoaded,
);
export const getJournals = createSelector(getMetadataState, fromMetadata.getJournals);
export const getJournalsLoading = createSelector(getMetadataState, fromMetadata.getJournalsLoading);
export const getJournalsLoaded = createSelector(getMetadataState, fromMetadata.getJournalsLoaded);
export const getUnitConversions = createSelector(getMetadataState, fromMetadata.getUnitConversions);
export const getUnitConversionsLoading = createSelector(
    getMetadataState,
    fromMetadata.getUnitConversionsLoading,
);
export const getUnitConversionsLoaded = createSelector(
    getMetadataState,
    fromMetadata.getUnitConversionsLoaded,
);
export const getArticlesIngestionDate = createSelector(
    getMetadataState,
    fromMetadata.getArticlesIngestionDate,
);
export const getArticlesIngestionDateLoading = createSelector(
    getMetadataState,
    fromMetadata.getArticlesIngestionDateLoading,
);
export const getArticlesIngestionDateLoaded = createSelector(
    getMetadataState,
    fromMetadata.getArticlesIngestionDateLoaded,
);
