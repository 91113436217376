/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { MetadataState } from './metadata-state.interface';
import {
    getLabsRequest,
    getHealthLabelsRequest,
    getIdentifiersRequest,
    clearIdentifiers,
    getProteinOriginsRequest,
    clearProteinOrigins,
    getPatentsCountsRequest,
    clearPatentsCounts,
    getContainingIngredientsRequest,
    clearContainingIngredients,
    getRelationshipsInfoRequest,
    clearRelationshipsInfo,
    getStudyTypesRequest,
    getJournalsRequest,
    getUnitConversionsRequest,
    getArticlesIngestionDateRequest,
    clearNextError,
} from './metadata.actions';
import {
    getLabs,
    getLabsLoading,
    getLabsLoaded,
    getHealthLabels,
    getHealthLabelsLoading,
    getHealthLabelsLoaded,
    getIdentifiers,
    getIdentifiersLoading,
    getIdentifiersLoaded,
    getProteinOrigins,
    getProteinOriginsLoading,
    getProteinOriginsLoaded,
    getPatentsCounts,
    getPatentsCountsLoading,
    getPatentsCountsLoaded,
    getContainingIngredients,
    getContainingIngredientsLoading,
    getContainingIngredientsLoaded,
    getRelationshipsInfo,
    getRelationshipsInfoLoading,
    getRelationshipsInfoLoaded,
    getStudyTypes,
    getStudyTypesLoading,
    getStudyTypesLoaded,
    getJournals,
    getJournalsLoading,
    getJournalsLoaded,
    getUnitConversions,
    getUnitConversionsLoading,
    getUnitConversionsLoaded,
    getArticlesIngestionDate,
    getArticlesIngestionDateLoading,
    getArticlesIngestionDateLoaded,
    getErrors,
} from './metadata.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import HealthLabel from './interfaces/health-label.interface';
import Identifiers from './types/identifiers.type';
import ProteinOrigins from './types/protein-origins.type';
import PatentsCounts from './types/patents-counts.type';
import ContainingIngredients from './types/containing-ingredients.type';
import OriginsInfoPerRelationship from './types/origins-info-per-relationship.type';
import UnitConversion from './interfaces/unit-conversion.interface';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class MetadataFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    labs$: Observable<string[]> = this.store.pipe(select(getLabs));
    labsLoading$: Observable<boolean> = this.store.pipe(select(getLabsLoading));
    labsLoaded$: Observable<boolean> = this.store.pipe(select(getLabsLoaded));
    healthLabels$: Observable<HealthLabel[]> = this.store.pipe(select(getHealthLabels));
    healthLabelsLoading$: Observable<boolean> = this.store.pipe(select(getHealthLabelsLoading));
    healthLabelsLoaded$: Observable<boolean> = this.store.pipe(select(getHealthLabelsLoaded));
    identifiers$: Observable<Identifiers> = this.store.pipe(select(getIdentifiers));
    identifiersLoading$: Observable<boolean> = this.store.pipe(select(getIdentifiersLoading));
    identifiersLoaded$: Observable<boolean> = this.store.pipe(select(getIdentifiersLoaded));
    proteinOrigins$: Observable<ProteinOrigins> = this.store.pipe(select(getProteinOrigins));
    proteinOriginsLoading$: Observable<boolean> = this.store.pipe(select(getProteinOriginsLoading));
    proteinOriginsLoaded$: Observable<boolean> = this.store.pipe(select(getProteinOriginsLoaded));
    patentsCounts$: Observable<PatentsCounts> = this.store.pipe(select(getPatentsCounts));
    patentsCountsLoading$: Observable<boolean> = this.store.pipe(select(getPatentsCountsLoading));
    patentsCountsLoaded$: Observable<boolean> = this.store.pipe(select(getPatentsCountsLoaded));
    containingIngredients$: Observable<ContainingIngredients> = this.store.pipe(
        select(getContainingIngredients),
    );
    containingIngredientsLoading$: Observable<boolean> = this.store.pipe(
        select(getContainingIngredientsLoading),
    );
    containingIngredientsLoaded$: Observable<boolean> = this.store.pipe(
        select(getContainingIngredientsLoaded),
    );
    relationshipsInfo$: Observable<Record<string, OriginsInfoPerRelationship>> = this.store.pipe(
        select(getRelationshipsInfo),
    );
    relationshipsInfoLoading$: Observable<boolean> = this.store.pipe(
        select(getRelationshipsInfoLoading),
    );
    relationshipsInfoLoaded$: Observable<boolean> = this.store.pipe(
        select(getRelationshipsInfoLoaded),
    );
    studyTypes$: Observable<string[]> = this.store.pipe(select(getStudyTypes));
    studyTypesLoading$: Observable<boolean> = this.store.pipe(select(getStudyTypesLoading));
    studyTypesLoaded$: Observable<boolean> = this.store.pipe(select(getStudyTypesLoaded));
    journals$: Observable<string[]> = this.store.pipe(select(getJournals));
    journalsLoading$: Observable<boolean> = this.store.pipe(select(getJournalsLoading));
    journalsLoaded$: Observable<boolean> = this.store.pipe(select(getJournalsLoaded));
    unitConversions$: Observable<UnitConversion[]> = this.store.pipe(select(getUnitConversions));
    unitConversionsLoading$: Observable<boolean> = this.store.pipe(
        select(getUnitConversionsLoading),
    );
    unitConversionsLoaded$: Observable<boolean> = this.store.pipe(select(getUnitConversionsLoaded));
    articlesIngestionDate$: Observable<string> = this.store.pipe(select(getArticlesIngestionDate));
    articlesIngestionDateLoading$: Observable<boolean> = this.store.pipe(
        select(getArticlesIngestionDateLoading),
    );
    articlesIngestionDateLoaded$: Observable<boolean> = this.store.pipe(
        select(getArticlesIngestionDateLoaded),
    );

    constructor(private store: Store<MetadataState>) {}

    getLabs(): void {
        this.store.dispatch(getLabsRequest());
    }

    getHealthLabels(): void {
        this.store.dispatch(getHealthLabelsRequest());
    }

    getIdentifiers(ids: string[], bookmarkIds?: BookmarkIds): void {
        this.store.dispatch(getIdentifiersRequest({ ids, bookmarkIds }));
    }

    clearIdentifiers(): void {
        this.store.dispatch(clearIdentifiers());
    }

    getProteinOrigins(ids: string[]): void {
        this.store.dispatch(getProteinOriginsRequest({ ids }));
    }

    clearProteinOrigins(): void {
        this.store.dispatch(clearProteinOrigins());
    }

    getPatentsCounts(ids: string[]): void {
        this.store.dispatch(getPatentsCountsRequest({ ids }));
    }

    clearPatentsCounts(): void {
        this.store.dispatch(clearPatentsCounts());
    }

    getContainingIngredients(ids: string[], bookmarkIds?: BookmarkIds): void {
        this.store.dispatch(getContainingIngredientsRequest({ ids, bookmarkIds }));
    }

    clearContainingIngredients(): void {
        this.store.dispatch(clearContainingIngredients());
    }

    getRelationshipsInfo(ids: [string, string][], bookmarkIds?: BookmarkIds): void {
        this.store.dispatch(getRelationshipsInfoRequest({ ids, bookmarkIds }));
    }

    clearRelationshipsInfo(): void {
        this.store.dispatch(clearRelationshipsInfo());
    }

    getStudyTypes(): void {
        this.store.dispatch(getStudyTypesRequest());
    }

    getJournals(): void {
        this.store.dispatch(getJournalsRequest());
    }

    getUnitConversions(): void {
        this.store.dispatch(getUnitConversionsRequest());
    }

    getArticlesIngestionDate(): void {
        this.store.dispatch(getArticlesIngestionDateRequest());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
