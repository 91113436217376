/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, mergeMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { BookmarksService } from './services/bookmarks.service';
import * as bookmarksActions from './bookmarks.actions';
import PaginatedBookmarks from './interfaces/paginated-bookmarks.interface';

@Injectable()
export class BookmarksEffects {
    constructor(private actions$: Actions, private bookmarksService: BookmarksService) {}

    getBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.getBookmarksRequest),
            mergeMap(
                ({
                    projectId,
                    bookmarkType,
                    filters,
                    preferences,
                    studyTypesOrder,
                    pageIndex,
                    pageSize,
                    sortingOptions,
                }) =>
                    this.bookmarksService
                        .getBookmarks(
                            projectId,
                            bookmarkType,
                            filters,
                            preferences,
                            studyTypesOrder,
                            pageIndex,
                            pageSize,
                            sortingOptions,
                        )
                        .pipe(
                            map((paginatedBookmarks: PaginatedBookmarks) =>
                                bookmarksActions.getBookmarksSuccess({
                                    paginatedBookmarks,
                                    bookmarkType,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(
                                    bookmarksActions.getBookmarksFailure({
                                        errorResponse,
                                        bookmarkType,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    createEntityBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.createEntityBookmarksRequest),
            switchMap(({ projects, origin, configuration, notes }) =>
                this.bookmarksService
                    .createEntityBookmarks(projects, origin, configuration, notes)
                    .pipe(
                        map(({ alert }) =>
                            bookmarksActions.createEntityBookmarksSuccess({ alert }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                bookmarksActions.createEntityBookmarksFailure({
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    createAssociationOpenBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.createAssociationOpenBookmarksRequest),
            switchMap(({ projects, configuration, notes }) =>
                this.bookmarksService
                    .createAssociationOpenBookmarks(projects, configuration, notes)
                    .pipe(
                        map(({ alert }) =>
                            bookmarksActions.createAssociationOpenBookmarksSuccess({ alert }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                bookmarksActions.createAssociationOpenBookmarksFailure({
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    createAssociationClosedBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.createAssociationClosedBookmarksRequest),
            switchMap(({ projects, configuration, notes }) =>
                this.bookmarksService
                    .createAssociationClosedBookmarks(projects, configuration, notes)
                    .pipe(
                        map(({ alert }) =>
                            bookmarksActions.createAssociationClosedBookmarksSuccess({
                                alert,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                bookmarksActions.createAssociationClosedBookmarksFailure({
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    createArticleBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.createArticleBookmarksRequest),
            switchMap(({ projects, origin, configurations, notes }) =>
                this.bookmarksService
                    .createArticleBookmarks(projects, origin, configurations, notes)
                    .pipe(
                        map(({ alert }) =>
                            bookmarksActions.createArticleBookmarksSuccess({
                                alert,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                bookmarksActions.createArticleBookmarksFailure({
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    updateNotes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.updateNotesRequest),
            switchMap(({ bookmarkIds, notes }) =>
                this.bookmarksService.updateNotes(bookmarkIds, notes).pipe(
                    map(() => bookmarksActions.updateNotesSuccess()),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            bookmarksActions.updateNotesFailure({
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    downloadArticleBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.downloadArticleBookmarksRequest),
            switchMap(({ projectId, filters }) =>
                this.bookmarksService.downloadArticleBookmarks(projectId, filters).pipe(
                    map((blob: Blob) => bookmarksActions.downloadArticleBookmarksSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(bookmarksActions.downloadArticleBookmarksFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType(bookmarksActions.deleteBookmarkRequest),
            mergeMap(({ projectId, bookmarkId, bookmarks, bookmarkType }) =>
                this.bookmarksService.deleteBookmark(projectId, bookmarkId, bookmarkType).pipe(
                    map(() =>
                        bookmarksActions.deleteBookmarkSuccess({
                            projectId,
                            bookmarkId,
                            bookmarks,
                            bookmarkType,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            bookmarksActions.deleteBookmarkFailure({
                                projectId,
                                bookmarkId,
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
