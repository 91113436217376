export enum AuthActionTypes {
    LOGIN_USER_REQUEST = '[AUTH] Login User Request',
    LOGIN_USER_SUCCESS = '[AUTH] Login User Success',
    LOGIN_USER_FAILURE = '[AUTH] Login User Failure',
    LOGOUT_USER_REQUEST = '[AUTH] Logout User Request',
    LOGOUT_USER_SUCCESS = '[AUTH] Logout User Success',
    LOGOUT_USER_FAILURE = '[AUTH] Logout User Failure',
    REHYDRATE_TOKEN = '[AUTH] Rehydrate Token',
    REHYDRATE_TOKEN_SUCCESS = '[AUTH] Rehydrate Token Success',
    REHYDRATE_TOKEN_FAILURE = '[AUTH] Rehydrate Token Failure',
    REFRESH_LOGIN_REQUEST = '[AUTH] Refresh Login Request',
    REFRESH_LOGIN_SUCCESS = '[AUTH] Refresh Login Success',
    REFRESH_LOGIN_FAILURE = '[AUTH] Refresh Login Failure',
    GET_CURRENT_USER = '[AUTH] Get Current User',
    GET_CURRENT_USER_SUCCESS = '[AUTH] Get Current User Success',
    GET_CURRENT_USER_FAILURE = '[AUTH] Get Current User Failure',
    ACCEPT_TERMS_OF_USE_REQUEST = '[AUTH] Accept Terms of Use Request',
    ACCEPT_TERMS_OF_USE_SUCCESS = '[AUTH] Accept Terms of Use Success',
    ACCEPT_TERMS_OF_USE_FAILURE = '[AUTH] Accept Terms of Use Failure',
    RESET_AUTH = '[AUTH] Reset Auth',
    CLEAR_NEXT_ERROR = '[AUTH] Clear Next Error',
}
