import { ASSOCIATION } from './discovery';
import Bookmark from '@leap-store/core/src/lib/data/bookmarks/enums/bookmark.enum';

export const BOOKMARK: string = 'Bookmark';

export const BOOKMARK_ENTITY_TEXT: string = `${BOOKMARK} ${Bookmark.entity}`;
export const BOOKMARK_ASSOCIATION_TEXT: string = `${BOOKMARK} ${ASSOCIATION}`;
export const BOOKMARK_ARTICLE_TEXT: string = `${BOOKMARK} ${Bookmark.article}`;

export const NOTES_TITLE: string = 'Notes';
export const NOTES_VIEW_PLACEHOLDER: string = 'No notes added for this bookmark.';
export const NOTES_EDIT_PLACEHOLDER: string = 'Type your notes for this bookmark...';
export const EDIT_NOTES_TEXT: string = 'Edit note';
export const ADD_NOTES_PLACEHOLDER_TEXT: string = 'Type your note here...';
export const ADD_NOTES_TEXT: string = 'Add note';
export const HIDE_NOTES_TEXT: string = 'Hide editor';
export const CHARACTER_LIMIT_ERROR: string =
    'The maximum number of characters allowed in this field is ';
export const NOTES_CHARACTER_LIMIT: number = 1000;
export const NOTES_CHARACTER_LIMIT_ERROR: string = `${CHARACTER_LIMIT_ERROR}${NOTES_CHARACTER_LIMIT}.`;
