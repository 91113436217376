import { DOI_URL } from '@leap-common/constants/common';

export const NAME_LABEL: string = 'Name';
export const CATEGORIES_LABEL: string = 'Categories';
export const SUBCATEGORIES_LABEL: string = 'Subcategories';
export const HEALTH_LABELS_LABEL: string = 'Health areas';
export const LABS_LABEL: string = 'UCD DMD lab';
export const MOLECULES_LABEL: string = 'Molecule classification';
export const TAGS_LABEL: string = 'Tags';
export const STUDY_TYPES_LABEL: string = 'Type of studies';
export const STUDY_TYPE_LABEL: string = 'Type of study';
export const JOURNALS_LABEL: string = 'Journal';
export const SYNONYMS_LABEL: string = 'Synonyms';
export const DEFINITIONS_LABEL: string = 'Definitions';
export const DEFINITION_LABEL: string = 'Definition';
export const CONTAINING_INGREDIENTS_LABEL: string = 'Has concentration in';
export const PROTEIN_ORIGINS_LABEL: string = 'Source protein';
export const IDENTIFIERS_LABEL: string = 'External reference IDs';
export const MOLECULE_WEIGHT_LABEL: string = 'Molecular weight';
export const COW_MILK_CONCENTRATION_LABEL: string = "Concentration in cow's milk";
export const CONCENTRATION_LABEL: string = 'Concentration';
export const RELATIONSHIP_TYPES_LABEL: string = 'Relationship types';
export const RELATIONSHIP_TYPE_LABEL: string = 'Relationship type';
export const RELATIONSHIP_ORIGINS_LABEL: string = 'Relationship type source';
export const DATABASES_LABEL: string = 'Databases';
export const TARGETS_LABEL: string = 'Associated medical terms';
export const ORIGINS_LABEL: string = 'Publication source';
export const ASSOCIATIONS_LABEL: string = 'Associations';
export const STRENGTH_LABEL: string = 'Association strength';
export const DATE_RANGE_LABEL: string = 'Publication date range';
export const BIOMARKERS_LABEL: string = 'Exclude biomarkers';
export const EFFECTS_LABEL: string = 'Effect classification';
export const DOI_LABEL: string = 'DOI';
export const DOI_CONTENT: string = `<a href="${DOI_URL}%s" target="_blank" class="text-inherit"><u>%s</u></a>`;
